import { ShareIcon } from '@heroicons/react/24/outline'
import { nativeShare } from '../../../utils/native-share'

export default function SmallShareButton() {
    return (
        <button
            onClick={() => nativeShare()}
            className="inline-flex items-center justify-center rounded bg-neutral-200 px-6 py-2 font-serif text-lg font-semibold text-neutral-800 transition-colors hover:bg-neutral-300 hover:text-neutral-900 focus:outline-none focus:ring-2 focus:ring-primary-500"
        >
            <ShareIcon aria-hidden="true" className="-ml-1 mr-2 h-4 w-4" />
            <span>Partager</span>
        </button>
    )
}
