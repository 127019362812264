import type { Resource } from '~/types/api'
import { getDomain } from '~/utils'
import LanguageBadge from '../../Badges/LanguageBadge'
import TagBadge from '../../Badges/TagBadge'
import TypeBadge from '../../Badges/TypeBadge'

export type Props = {
    resource: Resource
}

export default function ResourceCard({ resource }: Props) {
    return (
        <a
            href={resource.url}
            target="_blank"
            className="flex flex-col rounded border border-neutral-200 bg-white p-4 transition-colors hover:border-primary-500 hover:bg-primary-50 focus:border-primary-300 focus:outline-none focus:ring-2 focus:ring-primary-300"
        >
            <div className="mb-1 flex flex-wrap">
                <LanguageBadge language={resource.language} />
                <TypeBadge type={resource.type} />
            </div>
            <h3 className="font-serif text-xl font-semibold">
                {resource.title}
            </h3>
            <p className="mb-3 flex items-center justify-between text-sm text-neutral-500">
                <span>{getDomain(resource.url)}</span>
                <span className="ml-2">
                    Ajout le{' '}
                    {resource.createdAt.toLocaleDateString('fr-FR', {
                        dateStyle: 'short',
                    })}
                </span>
            </p>
            <p className="mb-3 text-neutral-700">{resource.description}</p>
            <div className="mt-auto flex flex-wrap">
                {resource.tags.map((tag) => (
                    <TagBadge key={tag.id} tag={tag} />
                ))}
            </div>
        </a>
    )
}
