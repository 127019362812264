import { ArrowPathIcon } from '@heroicons/react/24/outline'
import useResourcesFilter from '~/hooks/use-resources-filters'

export default function ResetButton() {
    const { reset } = useResourcesFilter()

    return (
        <button
            className="inline-flex w-full items-center justify-center space-x-2 rounded bg-red-500 px-4 py-2.5 font-semibold text-white transition-colors hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
            onClick={() => reset()}
        >
            <ArrowPathIcon aria-hidden="true" className="h-4 w-4" />
            <span>Réinitialiser</span>
        </button>
    )
}
