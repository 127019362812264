import { useAtomValue, useSetAtom } from 'jotai'
import type { z } from 'zod'
import { openContributeMenuAtom } from '~/stores/layout'
import { schema } from './schema'
import Form from '~/components/shared/Form/Form'
import useFormHandler from '~/hooks/use-form-handler'
import TextInput from '~/components/shared/Form/TextInput'
import TextareaInput from '~/components/shared/Form/TextareaInput'
import CheckboxInput from '~/components/shared/Form/CheckboxInput'
import SelectInput from '~/components/shared/Form/SelectInput'
import { languagesAtom, typesAtom } from '~/stores/resources'

export default function FormComponent() {
    const setOpen = useSetAtom(openContributeMenuAtom)
    const languages = useAtomValue(languagesAtom)
    const types = useAtomValue(typesAtom)

    async function submit(data: z.infer<typeof schema>) {
        if (!data.accept) {
            alert('Vous devez accepter nos conditions.')
            return
        }
        const res = await fetch(
            `${window.location.origin}/api/backend/post-resource-contribution`,
            {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        )
        if (res.status === 200) {
            alert('Contribution effectuée, merci !')
            setOpen(false)
        }
    }

    const { register, errors, handleSubmit } = useFormHandler(schema)

    return (
        <Form onSubmit={handleSubmit((data) => submit(data))}>
            <TextInput
                label="Titre"
                placeholder="60 caractères maximum"
                type="text"
                error={errors.title?.message}
                {...register('title')}
            />
            <TextareaInput
                label="Description"
                placeholder="200 caractères maximum"
                error={errors.description?.message}
                {...register('description')}
            />
            <TextInput
                label="Lien (URL)"
                placeholder="https://exemple.fr"
                type="url"
                required
                error={errors.url?.message}
                {...register('url')}
            />
            <SelectInput
                label="Langue régionale"
                options={[
                    ...languages.map((l) => ({
                        value: l.id.toString(),
                        text: l.name,
                    })),
                    { value: 'other', text: 'Autre (précisez en commentaire)' },
                ]}
                required
                error={errors?.language?.message}
                {...register('language')}
            />
            <SelectInput
                label="Nature"
                options={[
                    ...types.map((t) => ({
                        value: t.id.toString(),
                        text: t.name,
                    })),
                    { value: 'other', text: 'Autre (précisez en commentaire)' },
                ]}
                error={errors?.type?.message}
                {...register('type')}
            />
            <TextInput
                label="Thématiques"
                placeholder="Exemple : Chansons, débutants, reportages"
                type="text"
                error={errors?.tags?.message}
                {...register('tags')}
            />
            <TextareaInput
                label="Commentaire"
                placeholder="300 caractères maximum"
                hint="Ce commentaire est destiné aux administrateurs."
                error={errors.comment?.message}
                {...register('comment')}
            />
            <TextInput
                label="Votre email"
                placeholder="exemple@gmail.com"
                hint="Vous pouvez indiquer votre e-mail pour permettre aux administrateurs du site d'entrer en contact avec vous si besoin."
                type="email"
                error={errors?.email?.message}
                {...register('email')}
            />
            <CheckboxInput
                label="Je comprends que mes données ne seront pas transmises à des tiers et j'accepte."
                required
                error={errors?.accept?.message}
                {...register('accept')}
            />
        </Form>
    )
}
