export default {
    // Options.jsx
    items_per_page: '/ page',
    jump_to: 'Aller à',
    jump_to_confirm: 'confirmer',
    page: 'Page',

    // Pagination.jsx
    prev_page: 'Page précédente',
    next_page: 'Page suivante',
    prev_5: '5 Pages précédentes',
    next_5: '5 Pages suivantes',
    prev_3: '3 Pages précédentes',
    next_3: '3 Pages suivantes',
    page_size: 'taille de la page',
}
