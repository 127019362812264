import LanguageFilter from './Filters/LanguageFilter'
import TagsFilter from './Filters/TagsFilter'
import TypesFilter from './Filters/TypesFilter'

export default function Filters() {
    return (
        <div className="grid gap-8">
            <LanguageFilter />
            <TypesFilter />
            <TagsFilter />
        </div>
    )
}
