import { useSetAtom } from 'jotai'
import { openFilterMenuAtom } from '~/stores/layout'
import { AdjustmentsHorizontalIcon } from '@heroicons/react/24/solid'

export default function MediumFilterButton() {
    const setOpen = useSetAtom(openFilterMenuAtom)
    return (
        <button
            onClick={() => setOpen(true)}
            className="ml-4 hidden items-center rounded bg-primary-300 px-6 py-2 font-serif text-lg font-semibold text-neutral-800 transition-colors hover:bg-primary-400 hover:text-neutral-900 focus:outline-none focus:ring-2 focus:ring-primary-500 sm:inline-flex md:hidden"
        >
            <AdjustmentsHorizontalIcon
                aria-hidden="true"
                className="-ml-1 mr-2 h-5 w-5"
            />
            <span>Filtrer</span>
        </button>
    )
}
