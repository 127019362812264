import { useAtomValue } from 'jotai'
import {
    selectedLanguageAtom,
    selectedTagsAtom,
    selectedTypesAtom,
} from '~/stores/resources-filters'
import LanguageBadge from '../../Badges/LanguageBadge'
import TypeBadge from '../../Badges/TypeBadge'
import TagBadge from '../../Badges/TagBadge'

export default function SelectedTags() {
    const selectedLanguage = useAtomValue(selectedLanguageAtom)
    const selectedTypes = useAtomValue(selectedTypesAtom)
    const selectedTags = useAtomValue(selectedTagsAtom)

    return (
        <div className="bg-neutral-50">
            <div className="flex flex-wrap">
                {selectedLanguage && (
                    <LanguageBadge
                        language={selectedLanguage}
                        closable={true}
                    />
                )}
                {selectedTypes.map((type) => (
                    <TypeBadge key={type.id} type={type} closable={true} />
                ))}
                {selectedTags.map((tag) => (
                    <TagBadge key={tag.id} tag={tag} closable={true} />
                ))}
            </div>
        </div>
    )
}
