import { useAtom, useAtomValue } from 'jotai'
import { resourcesAtom, languagesAtom } from '~/stores/resources'
import { selectedLanguageAtom } from '~/stores/resources-filters'
import type { Language } from '~/types/api'

function LanguageField({ language }: { language: Language }) {
    const resources = useAtomValue(resourcesAtom)
    const [selectedLanguage, setSelectedLanguage] =
        useAtom(selectedLanguageAtom)

    function resetLanguage(checked: boolean, id: number) {
        if (checked && selectedLanguage?.id === id) {
            setSelectedLanguage(null)
        }
    }
    return (
        <label className="group flex items-center rounded p-1 transition-colors">
            <input
                className="peer form-radio h-5 w-5 rounded-full border-neutral-400 text-primary-400 focus:ring-primary-500"
                type="radio"
                checked={
                    selectedLanguage !== null && selectedLanguage === language
                }
                onChange={(e) => {
                    setSelectedLanguage(e.target.checked ? language : null)
                }}
                onClick={(e) =>
                    resetLanguage(e.currentTarget.checked, language.id)
                }
                onKeyDown={(e) => {
                    if (e.key === ' ' && e.currentTarget.checked) {
                        e.preventDefault()
                        resetLanguage(e.currentTarget.checked, language.id)
                    }
                }}
            />
            <span className="mx-2 text-neutral-700">{language.name}</span>
            <div className="ml-auto inline rounded bg-neutral-200 px-1.5 font-serif text-sm font-medium text-neutral-600 transition-colors hover:text-neutral-700 group-hover:bg-neutral-300 peer-disabled:bg-neutral-200 peer-disabled:text-neutral-600 peer-disabled:opacity-50">
                {resources.filter((r) => r.language.id === language.id).length}
            </div>
        </label>
    )
}

export default function LanguageFilter() {
    const languages = useAtomValue(languagesAtom)
    return (
        <fieldset>
            <legend className="mb-1 ml-1 font-serif text-xl font-semibold text-neutral-800">
                Langue
            </legend>
            {languages
                .filter((l) => l.special)
                .map((l, i) => (
                    <LanguageField key={i} language={l} />
                ))}
            <hr className="my-2 border-t border-t-neutral-200" />
            {languages
                .filter((l) => !l.special)
                .map((l, i) => (
                    <LanguageField key={i} language={l} />
                ))}
        </fieldset>
    )
}
