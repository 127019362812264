import LargeShareButton from '../ShareButtons/LargeShareButton'
import BackToTopButton from './BackToTopButton'
import Filters from './Filters/Filters'

export default function Sidebar({ initialURL }: { initialURL: URL }) {
    return (
        <div className="hidden md:block lg:col-span-2">
            <aside className="rounded border border-neutral-200 bg-white p-4">
                <Filters />
            </aside>
            <LargeShareButton url={initialURL} />
            <BackToTopButton />
        </div>
    )
}
