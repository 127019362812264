import { Transition } from '@headlessui/react'
import { ChevronDoubleUpIcon } from '@heroicons/react/20/solid'
import { Fragment, useEffect, useState } from 'react'
import SharedButtons from './Filters/SharedButtons'

export default function BackToTopButton() {
    const [isTop, setIsTop] = useState(true)

    function scrollToTop() {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }

    useEffect(() => {
        const handler = () => setIsTop(window.scrollY < 400)
        handler()
        window.addEventListener('scroll', handler, { passive: true })
        return () => {
            window.removeEventListener('scroll', handler)
        }
    }, [])

    return (
        <Transition
            as={Fragment}
            show={!isTop}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
        >
            <div className="sticky bottom-0">
                <div className="h-10 bg-gradient-to-t from-neutral-50 via-neutral-50 to-neutral-50/0"></div>
                <div className="bg-neutral-50 pb-2">
                    <button
                        onClick={() => scrollToTop()}
                        className="mb-2 inline-flex w-full items-center justify-center space-x-2 rounded-md border border-neutral-300 bg-white p-2 text-neutral-700 transition-shadow hover:shadow focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 focus:ring-offset-neutral-50"
                    >
                        <ChevronDoubleUpIcon className="h-4 w-4" />
                        <span>Haut de page</span>
                    </button>
                    <SharedButtons />
                </div>
            </div>
        </Transition>
    )
}
