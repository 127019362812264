import SearchBar from './SearchBar'
import SelectedTags from './SelectedTags'

export default function Search() {
    return (
        <div className="sticky top-0 z-20">
            <SearchBar />
            <SelectedTags />
            <div className="h-10 bg-gradient-to-b from-neutral-50 via-neutral-50 to-neutral-50/0"></div>
        </div>
    )
}
