import { XCircleIcon } from '@heroicons/react/20/solid'
import clsx from 'clsx'
import { useAtom } from 'jotai'
import { selectedTypesAtom } from '~/stores/resources-filters'
import type { ResourceType } from '~/types/api'
import { Icon } from '@iconify/react'

export default function TypeBadge({
    type,
    closable = false,
}: {
    type: ResourceType
    closable?: boolean
}) {
    const { palette } = type.color

    const bgColor = palette[200]
    const textColor = palette[900]

    const [selectedTypes, setSelectedTypes] = useAtom(selectedTypesAtom)
    const C = closable ? 'button' : 'div'

    return (
        <C
            onClick={
                closable
                    ? () => {
                          const temp = [...selectedTypes]
                          temp.splice(temp.indexOf(type), 1)
                          setSelectedTypes(temp)
                      }
                    : undefined
            }
            className={clsx(
                'mr-1 inline-flex items-center rounded-full px-3 py-1',
                closable
                    ? 'mt-1 transition-colors hover:!bg-red-200 focus:outline-none focus:ring-2 focus:ring-red-500 [&>*]:transition-colors [&>*]:hover:!text-red-800'
                    : 'mb-1 text-xs font-bold'
            )}
            style={{ backgroundColor: bgColor, color: textColor }}
        >
            <Icon
                icon={type.icon}
                aria-hidden="true"
                className={clsx(
                    '-ml-1 mr-1',
                    closable ? 'h-5 w-5' : 'h-[.875rem] w-[.875rem]'
                )}
            />
            <span>{type.name}</span>
            {closable && (
                <XCircleIcon
                    className={clsx('ml-1 -mr-1.5 h-5 w-5')}
                    aria-hidden="true"
                />
            )}
        </C>
    )
}
