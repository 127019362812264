import { CheckIcon } from '@heroicons/react/24/outline'
import { useSetAtom } from 'jotai'
import { openFilterMenuAtom } from '~/stores/layout'

export default function FakeValidateButton() {
    const setOpen = useSetAtom(openFilterMenuAtom)
    return (
        <button
            className="inline-flex w-full items-center justify-center space-x-2 rounded bg-lime-600 px-4 py-2.5 font-semibold text-white transition-colors hover:bg-lime-500 focus:outline-none focus:ring-2 focus:ring-lime-500 focus:ring-offset-2"
            onClick={() => setOpen(false)}
        >
            <CheckIcon aria-hidden="true" className="h-4 w-4" />
            <span>Appliquer</span>
        </button>
    )
}
