import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { useAtom } from 'jotai'
import { Fragment } from 'react'
import { openFilterMenuAtom } from '~/stores/layout'
import Filters from './Filters'
import SharedButtons from './SharedButtons'

export default function MobileFilters() {
    const [open, setOpen] = useAtom(openFilterMenuAtom)
    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-40" onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-neutral-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden">
                        <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                                    <div className="absolute top-0 left-0 -ml-10 flex pt-4 pr-2">
                                        <button
                                            type="button"
                                            className="rounded-l bg-primary-200 p-2 text-neutral-600 transition-colors hover:bg-primary-300 hover:text-neutral-900 focus:outline-none focus:ring-2 focus:ring-primary-500"
                                            onClick={() => setOpen(false)}
                                        >
                                            <span className="sr-only">
                                                Fermer le menu
                                            </span>
                                            <XMarkIcon
                                                className="h-6 w-6"
                                                aria-hidden="true"
                                            />
                                        </button>
                                    </div>
                                    <div className="flex h-full flex-col divide-y divide-neutral-200 bg-white shadow-xl">
                                        <div className="flex min-h-0 flex-1 flex-col overflow-y-auto">
                                            <div className="relative mt-6 flex flex-1 flex-col px-4 sm:px-6">
                                                <Filters />
                                                <div className="sticky bottom-0 mt-auto h-12 bg-gradient-to-t from-white via-white to-white/0"></div>
                                            </div>
                                        </div>
                                        <div className="flex-shrink-0 px-4 py-4">
                                            <SharedButtons mobile={true} />
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
