import { useAtom } from 'jotai'
import { searchAtom } from '~/stores/resources-filters'
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid'
import MediumShareButton from '../../ShareButtons/MediumShareButton'
import MediumFilterButton from '../../FilterButtons/MediumFilterButton'

export default function SearchBar() {
    const [search, setSearch] = useAtom(searchAtom)

    return (
        <div className="flex sm:-mx-4 sm:bg-neutral-50 sm:px-4 sm:pt-4 md:mx-auto md:bg-transparent md:p-0">
            <div className="w-full rounded-t-md bg-neutral-50">
                <label htmlFor="search" className="sr-only">
                    Rechercher
                </label>
                <div className="relative">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <MagnifyingGlassIcon
                            className="h-5 w-5 text-neutral-400"
                            aria-hidden="true"
                        />
                    </div>
                    <input
                        id="search"
                        name="search"
                        className="block w-full rounded-md border border-neutral-200 bg-white py-4 pl-10 pr-3 leading-5 placeholder-neutral-500 focus:border-primary-500 focus:placeholder-neutral-400 focus:outline-none focus:ring-1 focus:ring-primary-500"
                        placeholder="Rechercher"
                        type="search"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </div>
            </div>
            <MediumFilterButton />
            <MediumShareButton />
        </div>
    )
}
