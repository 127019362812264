import { z } from 'zod'
import { URL_RE } from '~/utils/regex'

export const schema = z.object({
    title: z.string().max(60, '60 caractères maximum').optional(),
    description: z.string().max(200, '200 caractères maximum').optional(),
    url: z
        .string({ required_error: 'Champ obligatoire' })
        .regex(URL_RE, 'URL invalide'),
    language: z.string({ required_error: 'Champ obligatoire' }),
    type: z.string().optional(),
    tags: z.string().optional(),
    comment: z.string().max(300, '300 caractères maximum').optional(),
    email: z
        .string()
        .email('Email invalide')
        .optional()
        .or(z.literal(''))
        .transform((v) => (v === '' ? undefined : v)),
    accept: z.boolean({ required_error: 'Champ obligatoire' }),
})
