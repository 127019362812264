// @ts-ignore
import Pagination from '~/components/shared/Pagination/Pagination.jsx'

const LOCALE = {
    // Options.jsx
    items_per_page: '/ page',
    jump_to: 'Aller à',
    jump_to_confirm: 'confirmer',
    page: 'Page',

    // Pagination.jsx
    prev_page: 'Page précédente',
    next_page: 'Page suivante',
    prev_5: '5 Pages précédentes',
    next_5: '5 Pages suivantes',
    prev_3: '3 Pages précédentes',
    next_3: '3 Pages suivantes',
    page_size: 'taille de la page',
}

type Props = {
    total: number
    current: number
    onChange: (page: number, pageSize: number) => void
    pageSize: number
}

export default function CustomPagination({
    total,
    current,
    onChange,
    pageSize,
}: Props) {
    return (
        <div className="flex justify-center">
            <Pagination
                total={total}
                current={current}
                onChange={onChange}
                pageSize={pageSize}
                showLessItems={true}
                locale={LOCALE}
                prevIcon={() => <>Précédent</>}
                nextIcon={() => <>Suivant</>}
                jumpPrevIcon={() => <>...</>}
                jumpNextIcon={() => <>...</>}
            />
        </div>
    )
}
