import { useAtom, useAtomValue } from 'jotai'
import { tagsAtom } from '~/stores/resources'
import {
    filteredResourcesByTypesAtom,
    selectedTagsAtom,
} from '~/stores/resources-filters'
import type { ResourceTag } from '~/types/api'
import { sortAlpha } from '~/utils'

function Tag({ tag }: { tag: ResourceTag }) {
    const resources = useAtomValue(filteredResourcesByTypesAtom)
    const [selectedTags, setSelectedTags] = useAtom(selectedTagsAtom)
    return (
        <label className="group flex items-center rounded p-1 transition-colors hover:bg-neutral-100">
            <input
                className="peer form-checkbox h-5 w-5 rounded border-neutral-400 text-primary-400 focus:ring-primary-500 disabled:opacity-50"
                type="checkbox"
                checked={selectedTags.includes(tag)}
                onChange={(e) => {
                    const temp = [...selectedTags]
                    if (e.target.checked) {
                        temp.push(tag)
                    } else {
                        temp.splice(temp.indexOf(tag), 1)
                    }
                    setSelectedTags(temp.sort(sortAlpha('name')))
                }}
            />
            <span className="mx-2 peer-disabled:opacity-50">{tag.name}</span>
            <div className="ml-auto inline rounded bg-neutral-200 px-1.5 font-serif text-sm font-medium text-neutral-600 transition-colors hover:text-neutral-700 group-hover:bg-neutral-300">
                {
                    resources.filter((r) =>
                        r.tags.map((t) => t.id).includes(tag.id)
                    ).length
                }
            </div>
        </label>
    )
}

export default function TagsFilter() {
    const tags = useAtomValue(tagsAtom)
    return (
        <fieldset>
            <legend className="mb-1 ml-1 font-serif text-xl font-semibold text-neutral-800">
                Thématiques
            </legend>
            {tags
                .filter((t) => t.special)
                .map((tag, i) => (
                    <Tag key={i} tag={tag} />
                ))}
            <hr className="my-2 border-t border-t-neutral-200" />
            {tags
                .filter((t) => !t.special)
                .map((tag, i) => (
                    <Tag key={i} tag={tag} />
                ))}
        </fieldset>
    )
}
