import {
    EllipsisHorizontalIcon,
    Squares2X2Icon,
} from '@heroicons/react/20/solid'
import clsx from 'clsx'
import { useSetAtom } from 'jotai'
import { paginatePreferenceAtom } from '~/stores/resources-filters'
import { capitalize } from '~/utils/primitives'
import { ITEMS_PER_PAGE } from './ResourcesList'

export default function PaginationSwitcher({
    paginate,
    length,
    start,
    end,
}: {
    paginate: boolean
    length: number
    start: number
    end: number
}) {
    const setPaginate = useSetAtom(paginatePreferenceAtom)

    const result = `résultat${length === 1 ? '' : 's'}`
    return (
        <div className="flex items-center justify-between space-x-4">
            {paginate ? (
                <div className="text-neutral-600">
                    {capitalize(result)}{' '}
                    <span className="font-serif font-semibold text-neutral-700">
                        {start}
                    </span>{' '}
                    à{' '}
                    <span className="font-serif font-semibold text-neutral-700">
                        {end}
                    </span>{' '}
                    sur{' '}
                    <span className="font-serif font-semibold text-neutral-700">
                        {length}
                    </span>
                </div>
            ) : (
                <div className="text-neutral-600">
                    <span className="font-serif font-semibold text-neutral-700">
                        {length}
                    </span>{' '}
                    {result}
                </div>
            )}
            <div className="inline-flex flex-shrink-0 -space-x-px">
                <button
                    className={clsx(
                        'inline-flex items-center space-x-1 rounded-l-md border border-neutral-300 px-3 py-1.5 text-sm transition-colors focus:z-10 focus:outline-none focus:ring-2 focus:ring-primary-500',
                        paginate
                            ? 'bg-primary-200 font-medium text-neutral-800'
                            : 'bg-white text-neutral-700'
                    )}
                    onClick={() => setPaginate(true)}
                >
                    <EllipsisHorizontalIcon
                        aria-hidden="true"
                        className="-ml-1 h-4 w-4"
                    />
                    <span>{ITEMS_PER_PAGE} par page</span>
                </button>
                <button
                    className={clsx(
                        'inline-flex items-center space-x-1 rounded-r-md border border-neutral-300 px-3 py-1.5 text-sm transition-colors focus:z-10 focus:outline-none focus:ring-2 focus:ring-primary-500',
                        !paginate
                            ? 'bg-primary-200 font-medium text-neutral-800'
                            : 'bg-white text-neutral-700'
                    )}
                    onClick={() => setPaginate(false)}
                >
                    <Squares2X2Icon
                        aria-hidden="true"
                        className="-ml-1 h-4 w-4"
                    />
                    <span>Tous</span>
                </button>
            </div>
        </div>
    )
}
