import { GlobeEuropeAfricaIcon, XCircleIcon } from '@heroicons/react/20/solid'
import clsx from 'clsx'
import { useSetAtom } from 'jotai'
import { selectedLanguageAtom } from '~/stores/resources-filters'
import type { Language } from '~/types/api'

export default function LanguageBadge({
    language,
    closable = false,
}: {
    language: Language
    closable?: boolean
}) {
    const setSelectedLanguage = useSetAtom(selectedLanguageAtom)
    const C = closable ? 'button' : 'div'

    return (
        <C
            onClick={closable ? () => setSelectedLanguage(null) : undefined}
            className={clsx(
                'mr-1 inline-flex items-center rounded-full bg-neutral-200 px-3 py-1 text-neutral-900',
                closable
                    ? 'mt-1 transition-colors hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-red-500 [&>*]:transition-colors [&>*]:hover:!text-red-800'
                    : 'mb-1 text-xs font-bold'
            )}
        >
            <GlobeEuropeAfricaIcon
                aria-hidden="true"
                className={clsx(
                    '-ml-1.5 mr-1 text-neutral-600',
                    closable ? 'h-5 w-5' : 'h-4 w-4'
                )}
            />
            <span>{language.name}</span>
            {closable && (
                <XCircleIcon
                    className={clsx('ml-1 -mr-1.5 h-5 w-5 text-neutral-600')}
                    aria-hidden="true"
                />
            )}
        </C>
    )
}
