import { atom } from 'jotai'
import Fuse from 'fuse.js'
import type { Language, Resource, ResourceTag, ResourceType } from '~/types/api'
import { resourcesAtom } from '~/stores/resources'
import { sortAlpha } from '~/utils'

export const selectedLanguageAtom = atom<null | Language>(null)
export const selectedTagsAtom = atom<ResourceTag[]>([])
export const selectedTypesAtom = atom<ResourceType[]>([])

export const searchAtom = atom('')

export const filteredResourcesByLanguageAtom = atom((get) =>
    get(resourcesAtom).filter(({ language }) => {
        const selectedLanguage = get(selectedLanguageAtom)
        return selectedLanguage ? language.id === selectedLanguage.id : true
    })
)

export const filteredResourcesByTypesAtom = atom((get) =>
    get(filteredResourcesByLanguageAtom).filter(({ type }) => {
        const selectedTypes = get(selectedTypesAtom)
        if (selectedTypes.length === 0) return true
        return selectedTypes.map((e) => e.id).includes(type.id)
    })
)

export const resourcesFiltersActiveAtom = atom(
    (get) =>
        get(selectedLanguageAtom) !== null ||
        get(selectedTypesAtom).length !== 0 ||
        get(selectedTagsAtom).length !== 0 ||
        get(searchAtom) !== ''
)

export const filteredResourcesAtom = atom((get) => {
    const filtered = get(filteredResourcesByTypesAtom).filter(({ tags }) => {
        const selectedTags = get(selectedTagsAtom)
        if (selectedTags.length === 0) return true
        for (const tag of selectedTags) {
            if (!tags.map((e) => e.id).includes(tag.id)) {
                return false
            }
        }
        return true
    })

    let results: Resource[] = filtered
    const searchString = get(searchAtom)
    if (searchString !== '') {
        const fuse = new Fuse(filtered, {
            keys: ['title', 'description'],
            threshold: 0.2,
            ignoreLocation: true,
        })
        results = fuse.search(searchString).map((e) => e.item)
    }

    const filtersActive = get(resourcesFiltersActiveAtom)

    type SortFunc = Parameters<typeof results.sort>[0]
    const sortByTitle: SortFunc = sortAlpha('title')
    const sortByCreationDate: SortFunc = (a, b) => +b.createdAt - +a.createdAt
    const sortFunc = filtersActive ? sortByTitle : sortByCreationDate

    return results.sort(sortFunc)
})

export const paginatePreferenceAtom = atom(true)
