import SmallFilterButton from '../FilterButtons/SmallFilterButton'
import ResourcesList from './ResourcesList/ResourcesList'
import SmallShareButton from '../ShareButtons/SmallShareButton'
import Search from './Search/Search'

export default function MainArea() {
    return (
        <div className="md:col-span-2 lg:col-span-5">
            <Search />
            <ResourcesList />
            <div className="sticky bottom-0 mt-8 sm:hidden">
                <div className="h-20 bg-gradient-to-t from-neutral-50 via-neutral-50 to-neutral-50/0"></div>
                <div className="flex space-x-4 bg-neutral-50">
                    <SmallFilterButton />
                    <SmallShareButton />
                </div>
            </div>
        </div>
    )
}
