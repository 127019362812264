import clsx from 'clsx'
import { useAtomValue } from 'jotai'
import ResourceContributionButton from '~/components/shared/ResourceContributionButton'
import { resourcesFiltersActiveAtom } from '~/stores/resources-filters'
import FakeValidateButton from './FakeValidateButton'
import ResetButton from './ResetButton'

export default function SharedButtons({
    mobile = false,
}: {
    mobile?: boolean
}) {
    const canReset = useAtomValue(resourcesFiltersActiveAtom)

    return (
        <>
            <div className={clsx(canReset && 'mb-2')}>
                <ResourceContributionButton />
            </div>
            {canReset && <ResetButton />}
            {mobile && (
                <div className="mt-2">
                    <FakeValidateButton />
                </div>
            )}
        </>
    )
}
