import { useAtom, useAtomValue } from 'jotai'
import { typesAtom } from '~/stores/resources'
import {
    filteredResourcesByLanguageAtom,
    selectedTypesAtom,
} from '~/stores/resources-filters'
import { sortAlpha } from '~/utils'

export default function TypesFilter() {
    const resources = useAtomValue(filteredResourcesByLanguageAtom)
    const types = useAtomValue(typesAtom)
    const [selectedTypes, setSelectedTypes] = useAtom(selectedTypesAtom)

    return (
        <fieldset>
            <legend className="mb-1 ml-1 font-serif text-xl font-semibold text-neutral-800">
                Nature
            </legend>
            {types.map((value) => (
                <label
                    key={value.id}
                    className="group flex items-center rounded p-1 transition-colors hover:bg-neutral-100"
                >
                    <input
                        className="peer form-checkbox h-5 w-5 rounded border-neutral-400 text-primary-400 focus:ring-primary-500 disabled:opacity-50"
                        type="checkbox"
                        checked={selectedTypes.includes(value)}
                        onChange={(e) => {
                            const temp = [...selectedTypes]
                            if (e.target.checked) {
                                temp.push(value)
                            } else {
                                temp.splice(temp.indexOf(value), 1)
                            }
                            setSelectedTypes(temp.sort(sortAlpha('name')))
                        }}
                    />
                    <span className="mx-2 text-neutral-700 peer-disabled:opacity-50">
                        {value.name}
                    </span>
                    <div className="ml-auto inline rounded bg-neutral-200 px-1.5 font-serif text-sm font-medium text-neutral-600 transition-colors hover:text-neutral-700 group-hover:bg-neutral-300">
                        {resources.filter((r) => r.type.id === value.id).length}
                    </div>
                </label>
            ))}
        </fieldset>
    )
}
