import { XCircleIcon } from '@heroicons/react/20/solid'
import clsx from 'clsx'
import { useAtom } from 'jotai'
import { selectedTagsAtom } from '~/stores/resources-filters'
import type { ResourceTag } from '~/types/api'

export default function TagBadge({
    tag,
    closable = false,
}: {
    tag: ResourceTag
    closable?: boolean
}) {
    const [selectedTags, setSelectedTags] = useAtom(selectedTagsAtom)
    const C = closable ? 'button' : 'div'

    return (
        <C
            onClick={
                closable
                    ? () => {
                          const temp = [...selectedTags]
                          temp.splice(temp.indexOf(tag), 1)
                          setSelectedTags(temp)
                      }
                    : undefined
            }
            className={clsx(
                'mt-1 mr-1 inline-flex items-center rounded-full bg-primary-200 px-3 py-1 text-neutral-900',
                closable
                    ? 'transition-colors hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-red-500 [&>*]:transition-colors [&>*]:hover:!text-red-800'
                    : 'text-xs font-bold'
            )}
        >
            <span>{tag.name}</span>
            {closable && (
                <XCircleIcon
                    className={clsx('ml-1 -mr-1.5 h-5 w-5 text-primary-600')}
                    aria-hidden="true"
                />
            )}
        </C>
    )
}
