import { useHydrateAtoms } from 'jotai/utils'
import useResourcesFilter from '~/hooks/use-resources-filters'
import { isBotAtom, resourcesAtom, languagesAtom } from '~/stores/resources'
import type { Language, Resource } from '~/types/api'
import ResourceContributionForm from '../Forms/ResourceContribution/ResourceContributionForm'
import MainArea from './MainArea/MainArea'
import MobileFilters from './Sidebar/Filters/MobileFilters'
import Sidebar from './Sidebar/Sidebar'

export type Props = {
    resources: Resource[]
    languages: Language[]
    initialURL: URL
    isBot: boolean
}

export default function ResourcesExplorer({
    resources,
    languages,
    initialURL,
    isBot,
}: Props) {
    useHydrateAtoms([
        [resourcesAtom, resources],
        [languagesAtom, languages],
        [isBotAtom, isBot],
    ])

    const { handleURLSearchParams, init } = useResourcesFilter()
    init(initialURL)
    handleURLSearchParams()
    return (
        <>
            <div
                id="browse"
                className="custom-container relative -mt-7 mb-16 grid w-full grid-cols-1 gap-6 px-4 md:grid-cols-3 lg:grid-cols-7"
            >
                <Sidebar initialURL={initialURL} />
                <MainArea />
            </div>
            <ResourceContributionForm />
            <MobileFilters />
        </>
    )
}
